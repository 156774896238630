*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Poppins', sans-serif;
  color: #f6f6f6;
  font-size: 16px;
  font-weight: 400;
  background-color: #0a0a0b;
  overflow-x: hidden;
  scroll-behavior: smooth;
}


a{
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

h1,h2,h3,h4,h5{
  font-weight: 600;
}

h6{
  font-weight: 400;
}

.bg-lines {
  /* display: none; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -3;
  pointer-events: none;

}

@media screen and (max-width:768px) {
  .bg-lines{
    display: none;
  }
}

 .bg-lines span {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #fff;
  opacity: 0.1;
  z-index: -999;
}

.bg-lines .line-1 {
  left: 10%;
}
 .bg-lines .line-2 {
  left: 30%;
}
 .bg-lines .line-3 {
  left: 50%;
}
 .bg-lines .line-4 {
  left: 70%;
}
 .bg-lines .line-5 {
  left: calc(100% - 10%);
}